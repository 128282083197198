<div class="app-qr-code">
  <p>
    <l sid="36"/> :<br/>
    {{ activation_data.telno }}
  </p>
  <qrcode [qrdata]="activation_data.qr_code_data" [width]="240" [errorCorrectionLevel]="'H'"></qrcode>
  <mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onClose()"><l sid="37"/></button>
  </mat-dialog-actions>
</div>

