import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import {
  FormControl,
  Validators,
} from '@angular/forms';

import { ResponseNotification } from 'src/app/components/share/simple-notification/simple-notification.component';
import { CustomerApiService } from 'src/app/services/customer-api.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-topup',
  templateUrl: './topup.component.html',
  styleUrls: ['./topup.component.scss']
})
export class TopupComponent implements OnInit {
  private _esim_hash: string;
  public booking: any;
  public cf_item: any;
  public esim_item: any;
  public quota: any;

  public selection_error: boolean;

  public selected_period: any;
  public selected_period_fc: FormControl;
  public period_candidates: Array<any>;

  public selected_volume: any;
  public selected_volume_fc: FormControl;
  public volume_candidates: Array<any>;

  public card_name: string;
  public card_name_fc: FormControl;

  public card_number: string;
  public card_number_fc: FormControl;
  public card_number_invalid: boolean;

  public card_mmyy: string;
  public card_mmyy_fc: FormControl;
  public card_mmyy_invalid: boolean;

  public card_cvv: string;
  public card_cvv_fc: FormControl;

  constructor(
    private _customerApiService: CustomerApiService,
    private _spinnerService: SpinnerService,
    public router: Router,
    private _snackBar: MatSnackBar,
    private _route: ActivatedRoute
  ) {
    this._esim_hash = "";
    this.period_candidates = new Array<any>();
    this.volume_candidates = new Array<any>();

    this.selection_error = false;

    this.selected_period = 0;
    this.selected_period_fc = new FormControl('', [Validators.required]);

    this.selected_volume = { id: 0, period: 0, volume: 0, sell_price: 0 };
    this.selected_volume_fc = new FormControl('', [Validators.required]);

    this.card_name = '';
    this.card_name_fc = new FormControl('', [Validators.required, Validators.maxLength(24)])

    this.card_number = '';
    this.card_number_fc = new FormControl('', [Validators.required]);
    this.card_number_invalid = false;

    this.card_mmyy = '';
    this.card_mmyy_fc = new FormControl('', [Validators.required]);
    this.card_mmyy_invalid = false;

    this.card_cvv = '';
    this.card_cvv_fc = new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(4)])
  }

  public get selected_period_item(): any {
    const candidates = new Array<any>;
    candidates.push({ id: 0, period: 0, volume: 0, sell_price: 0 })
    this.period_candidates.map(candidate => { candidates.push(candidate) });
    return candidates.find(candidate => candidate.period == this.selected_period);
  }

  public get selected_volume_item(): any {
    const candidates = new Array<any>;
    candidates.push({ id: 0, period: 0, volume: 0, sell_price: 0 })
    this.volume_candidates.map(candidate => { candidates.push(candidate) });
    return candidates.find(candidate => candidate.volume == this.selected_volume);
  }

  public topup_submit_available(): boolean {
    let has_error = false;
    if (this.selected_period === 0 && this.selected_volume === 0) {
      this.selected_period_fc.setErrors(['required']);
      this.selected_volume_fc.setErrors(['required']);
      this.selection_error = true;
      has_error = true;
    }
    else if ((this.selected_period !== null && this.selected_period > 0) || (this.selected_volume !== null && this.selected_volume > 0)) {
      this.selected_period_fc.setErrors(null);
      this.selected_volume_fc.setErrors(null);
      this.selection_error = false;
    }
    if (this.card_name.length === 0 || this.card_number.length === 0 || this.card_mmyy.length === 0 || this.card_cvv.length === 0) {
      this.card_name_fc.setErrors(['required']);
      this.card_number_fc.setErrors(['required']);
      this.card_mmyy_fc.setErrors(['required']);
      this.card_cvv_fc.setErrors(['required']);
      has_error = true;
    }
    return !has_error;
  }

  public get selected_price_total(): number {
    return Math.floor(this.selected_period_item.sell_price + this.selected_volume_item.sell_price);
  }

  public get selected_price_vat(): number {
    return Math.floor(this.selected_price_total * 0.1);
  }

  public get selected_price_total_w_vat(): number {
    return this.selected_price_total + this.selected_price_vat;
  }

  ngOnInit(): void {
    this._route.queryParamMap.subscribe((params) => {
      console.log("TopupComponent::ngOnInit : params ", params);
      const access_hash = params.get('hash');
      if (access_hash) {
        this._esim_hash = access_hash;
        console.log(`TopupComponent::ngOnInit : _esim_hash = ${this._esim_hash}`)
        this.updatePage();
      }
    });
  }

  private updatePage() {
    this._customerApiService.getEsimTopup(this._esim_hash).then((response) => {
      this._customerApiService.getInMaintenance().then(in_maintenance_result => {
        if (in_maintenance_result.result) {
          console.log("TopupComponent::updatePage : getInMaintenance : in_maintenance_result=", in_maintenance_result);
          if (in_maintenance_result.data.in_schedule) {
            ResponseNotification(this._snackBar, {
              result: false,
              message: 'We apologize, but we are currently undergoing maintenance.',
              data: null
            });
            this.router.navigate(['/booking'], { queryParams: { hash: response.data.booking.hash } });
          }
          else {
            if (response.result) {
              this.booking = response.data.booking;
              if (this.booking.status === 'CANCELLED') {
                this.booking.order = null;
                ResponseNotification(this._snackBar, {
                  result: false,
                  message: 'This contract has been terminated.',
                  data: null,
                });
                this.router.navigate(['/booking'], { queryParams: { hash: response.data.booking.hash } });
                return;
              }
              this.cf_item = response.data.cf_item;
              this.esim_item = response.data.esim;
              this.quota = response.data.quota;
              this.period_candidates = response.data.candidates.period;
              response.data.candidates.volume.forEach((candidate) => {
                // console.log('TopupComponent::updatePage : volume candidate', candidate);
                if (candidate.sell_price > 0) {
                  this.volume_candidates.push(candidate);
                }
              });
              this.selected_period = 0;
              this.selected_volume = 0;
            }
            else {
              console.warn("TopupComponent::updatePage - response NG", response);
              ResponseNotification(this._snackBar, response);
              this.router.navigate(['/booking'], { queryParams: { hash: response.data.booking.hash } });
            }
          }
        }
      });
    });
  }

  onPeriodVolumeChanged(): void {
    let has_error = false;
    if (this.selected_period === 0 && this.selected_volume === 0) {
      this.selected_period_fc.setErrors(['required']);
      this.selected_volume_fc.setErrors(['required']);
      this.selection_error = true;
      has_error = true;
    }
    else if ((this.selected_period !== null && this.selected_period > 0) || (this.selected_volume !== null && this.selected_volume > 0)) {
      this.selected_period_fc.setErrors(null);
      this.selected_volume_fc.setErrors(null);
      this.selection_error = false;
    }
  }

  onClickTopUp() {
    console.log(`TopupComponent::onClickTopUp period=${this.selected_period} volume=${this.selected_volume}`);
    console.log(`TopupComponent::onClickTopUp holder=${this.card_name} number=${this.card_number} mmyy=${this.card_mmyy} cvv=${this.card_cvv}`);
    this._spinnerService.start();
    if (!this.topup_submit_available()) {
      ResponseNotification(this._snackBar, {
        result: false,
        message: 'Please check your inputs',
        data: null
      });
      this._spinnerService.stop();
      return;
    }
    const yymm = this.card_mmyy.substring(2, 4) + this.card_mmyy.substring(0, 2);

    (<any>window).Multipayment.init(environment.gmo_shop_id);
    (<any>window).Multipayment.getToken({
      cardno: this.card_number,
      expire: yymm,
      securitycode: this.card_cvv,
      holdername: this.card_name,
      tokennumber: '2'
    },
      (repsonse) => {
        console.log('repsonse : ', repsonse);
        if (repsonse.resultCode !== '000') {
          this._spinnerService.stop();
          ResponseNotification(this._snackBar, {
            result: false,
            error: {
              message: 'Card information is incorrect'
            },
            data: null
          });
        } else {
          const token = repsonse.tokenObject.token[0];
          const token2 = repsonse.tokenObject.token[1];

          console.log(`TopupComponent::onClickTopUp period=${this.selected_period} volume=${this.selected_volume}`);
          this._customerApiService.postEsimTopup(
            this._esim_hash,
            this.selected_period_item,
            this.selected_volume_item,
            this.selected_price_total,
            this.selected_price_vat,
            this.selected_price_total_w_vat,
            token
          ).then((topup_result) => {
            this._spinnerService.stop();
            ResponseNotification(this._snackBar, topup_result);
            this.updatePage();
          });
        }
      }
    );
  }

  onCCNameChanged(ev: any): void {
    this.card_name = ev.target.value;
  }

  onCCNumberValueFilled(ev: any): void {
    this.card_number = ev;
  }
  onCCMmYyFocus(): void {
    this.card_mmyy_invalid = false;
  }
  onCCMmYyValueFilled(ev: any): void {
    this.card_mmyy_invalid = false;
    const mm = parseInt(ev.substring(0, 2));
    const yy = parseInt(ev.substring(2, 4));
    if (mm < 1 && mm > 12) {
      this.card_mmyy_invalid = true;
    }
    if (yy < 23) {
      this.card_mmyy_invalid = true;
    }
    this.card_mmyy = ev;
  }

  onCCCvvChanged(ev: any): void {
    this.card_cvv = ev.target.value;
  }
}
