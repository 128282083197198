<div role="group" class="credit-card-number-input-container"
     [formGroup]="parts"
     [attr.aria-labelledby]="_formField.getLabelId()"
     (focusin)="onFocusIn($event)"
     (focusout)="onFocusOut($event)">
  <input class="card-number-field-element"
         formControlName="c1" 
         maxLength="4"
         size="6"
         aria-label="1st field"
         inputmode="numeric"
         enterkeyhint="done"
         (input)="_handleInput(parts.controls.c1, c2)"
         #c1>
  <span class="card-number-field-spacer">&ndash;</span>
  <input class="card-number-field-element"
         formControlName="c2"
         maxLength="4"
         size="6"
         aria-label="2nd field"
         inputmode="numeric"
         enterkeyhint="done"
         (input)="_handleInput(parts.controls.c2, c3)"
         (keyup.backspace)="autoFocusPrev(parts.controls.c2, c1)"
         #c2>
  <span class="card-number-field-spacer">&ndash;</span>
  <input class="card-number-field-element"
         formControlName="c3"
         maxLength="4"
         size="6"
         aria-label="3rd field"
         inputmode="numeric"
         enterkeyhint="done"
         (input)="_handleInput(parts.controls.c3, c4)"
         (keyup.backspace)="autoFocusPrev(parts.controls.c3, c2)"
         #c3>
  <span class="card-number-field-spacer">&ndash;</span>
  <input class="card-number-field-element"
        formControlName="c4"
        maxLength="4"
        size="6"
        aria-label="4th field"
        inputmode="numeric"
        enterkeyhint="done"
        (input)="_handleInput(parts.controls.c4)"
        (keyup.backspace)="autoFocusPrev(parts.controls.c4, c3)"
        #c4>
</div>
