export const APP_DICTIONARY_EN  = [
  { "id":     1, "txt": "Japanese" },
  { "id":     2, "txt": "English" },
  { "id":    10, "txt": "Data only eSIM" },
  { "id":    11, "txt": "Booking information" },
  { "id":    12, "txt": "Booking code" },
  { "id":    13, "txt": "Creation date" },
  { "id":    14, "txt": "eSIM" },
  { "id":    15, "txt": "Plan" },
  { "id":    16, "txt": "Qty" },
  { "id":    17, "txt": "Line number" },
  { "id":    18, "txt": "ICCID" },
  { "id":    19, "txt": "Volume<wbr/>/Period" },
  { "id":    20, "txt": "Get QR-Code" },
  { "id":    21, "txt": "Notice" },
  { "id":    22, "txt": "Once you click the \"Confirmed and issue eSIM\" button, your eSIM plan will be issued and you will be able to get your eSIM profile." },
  { "id":    23, "txt": "After you issue your eSIM, <strong>please make sure to install it on your device within 48 hours</strong> by using the provided QR code (or manual installation with Activation code). " },
  { "id":    24, "txt": "Copied" },
  { "id":    25, "txt": "Install date" },
  { "id":    26, "txt": "End date" },
  { "id":    27, "txt": "###NOT USE###" },
  { "id":    28, "txt": "Please check the PDF displayed in the link below for instructions on how to install the eSIM profile into your device." },
  { "id":    29, "txt": "If you want to proceed with issuing your eSIM now, please check the boxes below and press the \"Confirmed and issue eSIM\" button." },
  { "id":    30, "txt": "I confirm that I have read the above instructions and will install the eSIM profile within 48 hours. " },
  { "id":    31, "txt": "I understand that the eSIM plan will start from the day when the eSIM is added into the device. " },
  { "id":    32, "txt": "I understand that the eSIM profile can be installed only 1 time. " },
  { "id":    33, "txt": "I understand that cancelation and refund will not be possible after issuing the eSIM. " },
  { "id":    34, "txt": "Confirmed and issue eSIM" },
  { "id":    35, "txt": "Cancel" },
  { "id":    36, "txt": "LINE NUMBER" },
  { "id":    37, "txt": "Close" },
  { "id":    38, "txt": "eSIM issued" },
  { "id":    39, "txt": "###NOT USE###" },
  { "id":    40, "txt": "Show Activation QR-Code" },
  { "id":    41, "txt": "iOS Manual" },
  { "id":    42, "txt": "Android Manual" },
  { "id":    43, "txt": "" },
  { "id":    44, "txt": "" },
  { "id":    45, "txt": "" },
  { "id":    46, "txt": "" },
  { "id":    47, "txt": "" },
  { "id":    48, "txt": "" },
  { "id":    49, "txt": "" },
  { "id":    50, "txt": "" },
  { "id":    51, "txt": "" },
  { "id":    52, "txt": "" },
  { "id":    53, "txt": "" },
  { "id":    54, "txt": "" },
  { "id":    55, "txt": "" },
  { "id":    56, "txt": "" },
  { "id":    57, "txt": "" },
  { "id":    58, "txt": "" },
  { "id":    59, "txt": "" },
  { "id":    60, "txt": "" },
  { "id":    61, "txt": "" },
  { "id":    62, "txt": "" },
  { "id":    63, "txt": "" },
  { "id":    64, "txt": "" },
  { "id":    65, "txt": "" },
  { "id":    66, "txt": "" },
  { "id":    67, "txt": "" },
  { "id":    68, "txt": "" },
  { "id":    69, "txt": "" },
  { "id":    70, "txt": "" }
];