import { nanoid } from 'nanoid';
import { Component, AfterViewInit, Inject, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

import { NotificationType } from 'src/app/common';

export function ResponseNotification(snackBar: MatSnackBar, response: any): void {
  console.log("simple-notification.component.ts::ResponseNotification response", response);
  if (response.result) {
    const ref = snackBar.openFromComponent(SimpleNotificationComponent, {
      duration: 3000,
      panelClass: ['info'],
      data: {
        type: NotificationType.info,
        message: response.message,
        actions: [
          {
            label: 'OK',
            fn: () => {
              ref.dismiss();
            }
          }
        ]
      }
    });
  }
  else {
    let error_message = response.message;
    if (response.error?.message) {
      error_message = response.error.message;
    }
    const ref = snackBar.openFromComponent(SimpleNotificationComponent, {
      duration: 10000,
      panelClass: ['error'],
      data: {
        type: NotificationType.error,
        message: error_message,
        actions: [
          {
            label: 'OK',
            fn: () => {
              ref.dismiss();
            }
          }
        ]
      }
    });
  }
}

@Component({
  selector: 'app-simple-notification',
  templateUrl: './simple-notification.component.html',
  styleUrls: ['./simple-notification.component.scss']
})

export class SimpleNotificationComponent implements AfterViewInit{
  
  message: string;
  actions: Array<any>;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {
    this.message = data.message;
    this.actions = data.actions;
    this.actions.forEach((action) => {
      action.button_id =  `simple-notification-${nanoid(4)}`;
    });
  }

  ngAfterViewInit(): void {
    this.actions.forEach((action) => {
      const el = document.getElementById(action.button_id);
      if (el) {
        el.onclick = action.fn;
      }
    });
  }
}
