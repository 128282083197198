import { Component, OnInit } from '@angular/core';
import { CustomerApiService } from 'src/app/services/customer-api.service';

@Component({
  selector: 'app-maintenance-info',
  templateUrl: './maintenance-info.component.html',
  styleUrls: ['./maintenance-info.component.scss']
})
export class MaintenanceInfoComponent implements OnInit {
  public in_maintenance: any;
  public planed_maintenances: Array<any>;

  constructor(
    private _customerApiService: CustomerApiService,
  ) {
    this.in_maintenance = null;
    this.planed_maintenances = [];
  }

  ngOnInit(): void {
    this._customerApiService.getInMaintenance().then(in_maintenance_result => {
      this.in_maintenance = null;
      if (in_maintenance_result.result) {
        this.in_maintenance = in_maintenance_result.data;
        console.log("MaintenanceInfoComponent::updatePage : ngOnInit : this.in_maintenance=", this.in_maintenance);
      }
    });
    this._customerApiService.getPlannedMaintenances().then(planned_mainenances_result => {
      this.planed_maintenances = [];
      if (planned_mainenances_result.result) {
        this.planed_maintenances = planned_mainenances_result.data;
        console.log("MaintenanceInfoComponent::updatePage : ngOnInit : this.planed_maintenances=", this.planed_maintenances);
      }
    });
  }
}
