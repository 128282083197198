// Check detail of this : 
// https://v15.material.angular.io/components/form-field/examples#form-field-custom-control

import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, AfterViewInit, Output, EventEmitter, ElementRef, Input, Optional, OnDestroy, ViewChild, Inject, Self } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  NgControl,
  Validators,
} from '@angular/forms';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { toNumberString, fixCustomNgInputField } from 'src/app/common';

/** Data structure for holding telephone number. */
export class CreditCardNumber {
  constructor(public c1: string, public c2: string, public c3: string, public c4: string) { }
}

@Component({
  selector: 'credit-card-number',
  templateUrl: './credit-card-number.component.html',
  styleUrls: ['./credit-card-number.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: CreditCardNumberComponent }],
  host: {
    '[class.credit-card-number-floating]': 'shouldLabelFloat',
    '[id]': 'id',
  },
})

export class CreditCardNumberComponent implements AfterViewInit, ControlValueAccessor, MatFormFieldControl<CreditCardNumber>, OnDestroy {
  static nextId = 0;
  @ViewChild('c1') c1Input: ElementRef<HTMLInputElement>;
  @ViewChild('c2') c2Input: ElementRef<HTMLInputElement>;
  @ViewChild('c3') c3Input: ElementRef<HTMLInputElement>;
  @ViewChild('c4') c4Input: ElementRef<HTMLInputElement>;

  parts = this._formBuilder.group({
    c1: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
    c2: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
    c3: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
    c4: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
  });
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = 'credit-card-input';
  id = `credit-card-input-${CreditCardNumberComponent.nextId++}`;
  onChange = (_: any) => { };
  onTouched = () => { };

  get empty() {
    const {
      value: { c1, c2, c3, c4 },
    } = this.parts;

    return !c1 && !c2 && !c3 && !c4;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input('aria-describedby') userAriaDescribedBy: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): CreditCardNumber | null {
    if (this.parts.valid) {
      const {
        value: { c1, c2, c3, c4 },
      } = this.parts;
      return new CreditCardNumber(c1!, c2!, c3!, c4!);
    }
    return null;
  }
  set value(cr_number: CreditCardNumber | null) {
    const { c1, c2, c3, c4 } = cr_number || new CreditCardNumber('', '', '', '');
    this.parts.setValue({ c1, c2, c3, c4 });
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return this.parts.invalid && this.touched;
  }

  @Output() valueFilled = new EventEmitter<string>();
  fireValueFilled() {
    this.valueFilled.emit(`${this.value?.c1}${this.value?.c2}${this.value?.c3}${this.value?.c4}`)
  }

  constructor(
    private _formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  private _ui_factors = [
    {hints: ['iphone', 'crios'], judge: 'iPhone/Chrome', factor: 1.0},
    {hints: ['iphone', 'edgios'], judge: 'iPhone/Edge', factor: 1.0},
    {hints: ['iphone', 'fxios'], judge: 'iPhone/Firefox', factor: 1.0},
    {hints: ['iphone', 'safari'], judge: 'iPhone/Safari', factor: 1.0},
    {hints: ['iphone'], judge: 'iPhone/Other', factor: 1.0}, // Opera, Sleipnir
    {hints: ['android', 'firefox'], judge: 'Android/Firefox', factor: 1.0},
    {hints: ['android', 'chrome', 'mobile', 'safari', 'edga'], judge: 'Android/edge', factor: 1.0},
    {hints: ['android', 'chrome', 'mobile', 'safari'], judge: 'Android/Chrome,Other', factor: 1.0},
    {hints: ['chrome'], judge: 'Desktop/Chrome,Other', factor: 1.0},
    {hints: ['firefox'], judge: 'Desktop/Firefox', factor: 1.0},
    {hints: ['safari'], judge: 'Desktop/Safari', factor: 1.0}
  ];

  ngAfterViewInit(): void {
    const container_tags = document.getElementsByTagName('credit-card-number');
    if (container_tags.length <= 0 || !container_tags[0]) {
      return;
    }
    fixCustomNgInputField(<HTMLElement>container_tags[0], 'credit-card-number-input-container', 'card-number-field-element', this._ui_factors);
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
      this.fireValueFilled();
    }
  }

  autoFocusNext(control: AbstractControl, nextElement?: HTMLInputElement): void {
    if (!control.errors && nextElement) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (control.value.length < 1) {
      this._focusMonitor.focusVia(prevElement, 'program');
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.credit-card-number-input-container',
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
    if (this.parts.controls.c4.valid) {
      this._focusMonitor.focusVia(this.c4Input, 'program');
    } else if (this.parts.controls.c3.valid) {
      this._focusMonitor.focusVia(this.c4Input, 'program');
    } else if (this.parts.controls.c2.valid) {
      this._focusMonitor.focusVia(this.c3Input, 'program');
    } else if (this.parts.controls.c1.valid) {
      this._focusMonitor.focusVia(this.c2Input, 'program');
    } else {
      this._focusMonitor.focusVia(this.c1Input, 'program');
    }
  }

  writeValue(cr_number: CreditCardNumber | null): void {
    this.value = cr_number;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
    control.setValue(this.toNumberString(control.value));
    this.autoFocusNext(control, nextElement);
    this.onChange(this.value);
  }

  toNumberString(input: string): string {
    return toNumberString(input);
  }
}
