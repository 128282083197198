<div class="app-confirmation">
  <div class="description">
    <h3>{{title}}</h3>
    <p [innerHTML]="safe_message"></p>
  </div>
  <mat-dialog-actions>
    <button mat-raised-button color="warn" (click)="onConfirmed()">{{this.label_confirm}}</button><br />
    <button mat-stroked-button color="Basic" (click)="onCancel()">{{this.label_cancel}}</button>
  </mat-dialog-actions>
</div>
