import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  private _display_subject: Subject<string>;
  public display$: Observable<string>

  constructor() {
    this._display_subject = new Subject();
    this.display$ = this._display_subject.asObservable();
    this._display_subject.next("none");
  }

  public start() {
    console.log("SpinnerService::start");
    this._display_subject.next("block");
  }

  public stop() {
    console.log("SpinnerService::stop");
    this._display_subject.next("none");
  }
}
