import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ResponseNotification } from 'src/app/components/share/simple-notification/simple-notification.component';
import { CustomerApiService } from 'src/app/services/customer-api.service';

@Component({
  selector: 'app-start-using-confirmation',
  templateUrl: './start-using-confirmation.component.html',
  styleUrls: ['./start-using-confirmation.component.scss']
})
export class StartUsingConfirmationComponent implements OnInit {

  public c1: boolean;
  public c2: boolean;
  public c3: boolean;
  public c4: boolean;
  private _cf_item_hash: string;

  constructor(
    private dialogRef: MatDialogRef<StartUsingConfirmationComponent>,
    private _CustomerApiService: CustomerApiService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.c1 = false;
    this.c2 = false;
    this.c3 = false;
    this.c4 = false;
    this._cf_item_hash = data.cf_item_hash;
  }

  ngOnInit(): void {
    console.log(`StartUsingConfirmationComponent::ngOnInit : ${this._cf_item_hash}`, event);

  }

  onConfirmed() {
    console.log("StartUsingConfirmationComponent::onConfirmed");
    this._CustomerApiService.postStartUsingEsim(this._cf_item_hash).then((stock_conf_result) => {
      ResponseNotification(this._snackBar, stock_conf_result);
    });
    this.dialogRef.close('confirmed');
  }

  onCancel() {
    console.log("StartUsingConfirmationComponent::onCancel");
    this.dialogRef.close('cancelled');
  }
}
