import { Component, Input, OnInit } from '@angular/core';
import { localizeString } from 'src/app/app.localize'

@Component({
  selector: 'l',
  templateUrl: './l.component.html',
  styleUrls: ['./l.component.scss']
})
export class LComponent implements OnInit {

  @Input() sid: string;

  constructor(
  ) {
  }

  ngOnInit(): void {
    console.log('LComponent::ngOnInit')
    const ltags = document.getElementsByTagName('l');
    Array.from(ltags).forEach(el => {
      if (el.attributes['sid']) {
        const html = localizeString(parseInt(el.attributes['sid'].value, 10));
        el.innerHTML = html;
      }
    })
  }
}
