import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';


import { ResponseNotification } from 'src/app/components/share/simple-notification/simple-notification.component';
import { CustomerApiService } from 'src/app/services/customer-api.service';

@Component({
  selector: 'app-usage',
  templateUrl: './usage.component.html',
  styleUrls: ['./usage.component.scss']
})
export class UsageComponent {
  private _esim_hash: string;
  public booking: any;
  public cf_item: any;
  public esim_item: any;
  public quota: any;
  public usage: any;

  constructor(
    private _customerApiService: CustomerApiService,
    public router: Router,
    private _snackBar: MatSnackBar,
    private _route: ActivatedRoute
  ) {
    this._esim_hash = "";
  }

  ngOnInit(): void {
    this._route.queryParamMap.subscribe((params) => {
      const access_hash = params.get('hash');
      if (access_hash) {
        this._esim_hash = access_hash;
        console.log(`UsageComponent::ngOnInit : _esim_hash = ${this._esim_hash}`)
        this.updatePage();
      }
    });
  }

  private updatePage() {
    this._customerApiService.getEsimUsage(this._esim_hash).then((response) => {
      this._customerApiService.getInMaintenance().then(in_maintenance_result => {
        if (in_maintenance_result.result) {
          console.log("TopupComponent::updatePage : getInMaintenance : in_maintenance_result=", in_maintenance_result);
          if (in_maintenance_result.data.in_schedule) {
            ResponseNotification(this._snackBar, {
              result: false,
              message: 'The system is currently under maintenance. Please try again later. We apologize for any inconvenience caused by this.',
              data: null
            });
            this.router.navigate(['/booking'], { queryParams: { hash: response.data.booking.hash } });
          }
          else {
            if (response.result) {
              this.booking = response.data.booking;
              if (this.booking.status === 'CANCELLED') {
                this.booking.order = null;
                ResponseNotification(this._snackBar, {
                  result: false,
                  message: 'This contract has been terminated.',
                  data: null,
                });
                this.router.navigate(['/booking'], { queryParams: { hash: response.data.booking.hash } });
                return;
              }
              this.cf_item = response.data.cf_item;
              this.esim_item = response.data.esim;
              this.quota = response.data.quota;
              this.usage = response.data.usage.reverse();
            }
            else {
              console.warn("UsageComponent::updatePage - response NG", response);
              ResponseNotification(this._snackBar, response);
              this.router.navigate(['/booking'], { queryParams: { hash: response.data.booking.access_hash } });
            }
          }
        }
      });
    });
  }
}
