import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';

import { ResponseNotification } from 'src/app/components/share/simple-notification/simple-notification.component';
import { CustomerApiService } from 'src/app/services/customer-api.service';

import { StartUsingConfirmationComponent } from 'src/app/components/dialogs/start-using-confirmation/start-using-confirmation.component';
import { QrCodeComponent } from 'src/app/components/dialogs/qr-code/qr-code.component';
import { SpinnerService } from 'src/app/services/spinner.service';
import { L, app_localization_initialize } from 'src/app/app.localize';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss']
})
export class BookingComponent implements OnInit {

  private _booking_hash: string;
  public booking: any;
  public in_maintenance: any;
  public test_txt: string;

  constructor(
    private _customerApiService: CustomerApiService,
    public router: Router,
    private _snackBar: MatSnackBar,
    private _route: ActivatedRoute,
    public dialog: MatDialog,
    private _spinnerService: SpinnerService,
    private clipboard: Clipboard
  ) {
    this._booking_hash = "";
    this.in_maintenance = null;
  }

  ngOnInit(): void {
    app_localization_initialize();
    this._route.queryParamMap.subscribe((params) => {
      const access_hash = params.get('hash');
      if (access_hash) {
        this._booking_hash = access_hash;
        console.log(`BookingComponent::ngOnInit : booking hash = ${this._booking_hash}`)
        this.updatePage();
      }
    });
  }

  private updatePage() {
    this._customerApiService.getInMaintenance().then(in_maintenance_result => {
      this.in_maintenance = null;
      if (in_maintenance_result.result) {
        this.in_maintenance = in_maintenance_result.data;
        console.log("BookingComponent::updatePage : getInMaintenance : this.in_maintenance=", this.in_maintenance);
      }
    });
    console.log(`BookingComponent::updatePage - hash : hash=${this._booking_hash}`);
    this._customerApiService.getBooking(this._booking_hash).then((booking_result) => {
      if (booking_result.result) {
        this.booking = booking_result.data;
        if (this.booking.status === 'CANCELLED') {
          this.booking.order = null;
          ResponseNotification(this._snackBar, {
            result: false,
            message: 'This contract has been terminated.',
            data: null,
          });
          return;
        }
        this.booking.order.items.forEach(item => {
          const blank_esims = Array<string>();
          console.log(`BookingComponent::updatePage - qty ${item.qty} : count ${item.esims.count}`);
          for (let i = 0; i < item.qty - item.esims.length; i++) {
            console.log(`BookingComponent::updatePage - blank_esims : #${i}`);
            blank_esims.push(`#${i + 1}`);
          }
          item.blank_esims = blank_esims;
        });
        console.log("BookingComponent::updatePage - booking : ", booking_result);
      }
      else {
        ResponseNotification(this._snackBar, booking_result);
      }
    });
  }

  public onStartUsingESim(cf_item_hash): void {
    const dialogRef = this.dialog.open(StartUsingConfirmationComponent, { data: { cf_item_hash: cf_item_hash } });
    dialogRef.afterClosed().subscribe((closed_by) => {
      if (closed_by === 'confirmed') {
        this._spinnerService.start();
        const self = this;
        setTimeout(() => {
          console.log("BookingComponent::onStartUsingESim : start delayed update (1)");
          self.updatePage();
          console.log("BookingComponent::onStartUsingESim : start delayed update (2)");
          this.updatePage();
          console.log("BookingComponent::onStartUsingESim : start delayed update (3)");
        }, 3000);
      }
    });
  }

  public onShowQRCode(telno: string, full_code: string): void {
    console.log("InstallationComponent::onShowQRCode");

    const dialogRef = this.dialog.open(QrCodeComponent, {
      data: {
        telno: telno,
        full_code: full_code,
        sm_dp_address: this.smDpAddress(full_code),
        activation_code: this.activationCode(full_code),
        qr_code_data: `LPA:${full_code}`
      }
    });
    dialogRef.afterClosed().subscribe((closed_by) => {
      console.log(`InstallationComponent::onShowQRCode dialog closed`);
    });
  }

  public smDpAddress(full_code: string): string {
    return full_code.split('$')[1];
  }

  public smDpAddressHtml(full_code: string): string {
    return this.smDpAddress(full_code).split('.').join('<wbr/>.').split('-').join('<wbr/>-');
  }

  public onCopySmDpAddress(code: string): void {
    this.clipboard.copy(code);
    ResponseNotification(this._snackBar, {
      result: true,
      message: L(24),
      data: null,
    });
  }

  public activationCode(full_code: string): string {
    return full_code.split('$')[2];
  }

  public activationCodeHtml(full_code: string): string {
    const parts = this.activationCode(full_code).split('-');
    const codes = parts[1].match(/.{20}/g);
    if (codes) {
      const excess = parts[1].substring(20);
      codes.push(excess);
      return `${parts[0]}<wbr/>-${codes.join('<wbr/>')}`;
    }
    return full_code;
  }

  public onCopyActivationCode(code: string): void {
    this.clipboard.copy(code);
    ResponseNotification(this._snackBar, {
      result: true,
      message: L(24),
      data: null,
    });
  }

  public fullActivationCodeHtml(full_code: string): string {
    return `1$${this.smDpAddressHtml(full_code)}$${this.activationCodeHtml(full_code)}`;
  }

  public onCopyFullActivationCode(code: string): void {
    this.clipboard.copy(code);
    ResponseNotification(this._snackBar, {
      result: true,
      message: L(24),
      data: null,
    });
  }

  public isToolAvailable(status: string): boolean {
    return status != 'EXPIRED' && status != 'CANCELLED' && status != 'DELETED' && status != 'UNSUBSCRIBED';
  }

  // public get contactUsUrl(): string {
  //   const booking_code = encodeURIComponent(this.booking.code);
  //   const customer_name = encodeURIComponent(this.booking.customer.name);
  //   const customer_email = encodeURIComponent(this.booking.customer.email);
  //   let url = `https://www.sakuramobile.jp/contact/esim/?bk_email=${customer_email}&bk_name=${customer_name}&bk_booking=${booking_code}`;
  //   return url;
  // }
}
