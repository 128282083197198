<div class="esim-topup" *ngIf="booking && cf_item && esim_item">
  <h2>Add data & Extension : {{ esim_item.telno }}</h2>
  Booking : <br />
  <table>
    <tbody>
      <tr>
        <td class="title">Booking</td>
        <td>
          <a class="link myesim" [routerLink]="['/booking']" [queryParams]="{ hash: booking.hash }">{{ booking.code }}</a>
        </td>
      </tr>
      <tr>
        <td class="title">Booking</td>
        <td>{{ booking.order_created_at | date : "y-MM-dd HH:mm" }}</td>
      </tr>
    </tbody>
  </table>
  <br />
  eSIM : {{ cf_item.category_title }}<br />
  <table class="esim_item">
    <tbody>
      <tr>
        <td class="title">Line number</td>
        <td>{{ esim_item.telno }}</td>
      </tr>
      <tr>
        <td class="title">ICCID</td>
        <td>{{ esim_item.iccid }}</td>
      </tr>
      <tr *ngIf="esim_item.installed_at">
        <td class="title">Volume/Period</td>
        <td>{{ esim_item.volume | decimalTo : 0 : "M" : "G" }} GB / {{ esim_item.period }} days</td>
      </tr>
      <tr>
        <td class="title">Installed</td>
        <td>{{ esim_item.installed_at | date : "y-MM-dd HH:mm" }}</td>
      </tr>
      <tr>
        <td class="title">End</td>
        <td>{{ esim_item.end_at | date : "y-MM-dd HH:mm" }}</td>
      </tr>
      <tr>
        <td class="title">Remaining data</td>
        <td colspan="3">
          <span class="main">{{ quota | bytesTo : 3 : "B" : "GB" }} GB</span>
          <a class="link myesim" [routerLink]="['/usage']" [queryParams]="{ hash: esim_item.hash }">Usage</a>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="select-form">
    <span class="main">Select Add data & Extension.</span><br />
    <div class="inline-container">
      <mat-form-field appearance="fill">
        <mat-label>Extension</mat-label>
        <mat-select required [(value)]="selected_period" [formControl]="selected_period_fc" (selectionChange)="onPeriodVolumeChanged()">
          <mat-option [value]="0">0 days</mat-option>
          <mat-option *ngFor="let candidate of period_candidates" [value]="candidate.period">
            {{ candidate.period }} days ({{ candidate.sell_price | currency : "JPY" : "symbol" }})
          </mat-option>
        </mat-select>
        <mat-error class="left">
          <span *ngIf="selected_period_fc.hasError('required') || selection_error"><strong>Add data & Extension must be selected</strong></span>
        </mat-error>
      </mat-form-field>
      &nbsp;
      <mat-form-field id="topup-extend-period" appearance="fill">
        <mat-label>Add data</mat-label>
        <mat-select required [(value)]="selected_volume" [formControl]="selected_volume_fc" (selectionChange)="onPeriodVolumeChanged()">
          <mat-option [value]="0">0 GB</mat-option>
          <mat-option *ngFor="let candidate of volume_candidates" [value]="candidate.volume">
            {{ candidate.volume | decimalTo : 0 : 'M' : 'G' }} GB ({{ candidate.sell_price | currency : "JPY" : "symbol" }})
          </mat-option>
        </mat-select>
        <mat-error class="left">
          <span *ngIf="selected_volume_fc.hasError('required') || selection_error"><strong>Add data & Extension must be selected</strong></span>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <table class="estimation">
    <thead>
      <tr>
        <th></th>
        <th>SELCTED</th>
        <th>PRICE</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="title">Extension</td>
        <td>{{ selected_period }} days</td>
        <td class="price">{{ selected_period_item.sell_price | currency : "JPY" : "symbol" }}</td>
      </tr>
      <tr>
        <td class="title">Add data & Extension</td>
        <td>{{ selected_volume | decimalTo : 0 : 'M' : 'G' }} GB</td>
        <td class="price">{{ selected_volume_item.sell_price | currency : "JPY" : "symbol" }}</td>
      </tr>
      <tr>
        <td class="title">Subtotal</td>
        <td colspan="2" class="price">{{ selected_price_total | currency : "JPY" : "symbol" }}</td>
      </tr>
      <tr>
        <td class="title">V.A.T.</td>
        <td colspan="2" class="price">{{ selected_price_vat | currency : "JPY" : "symbol" }}</td>
      </tr>
      <tr>
        <td class="title total">TOTAL</td>
        <td colspan="2" class="price total">{{ selected_price_total_w_vat | currency : "JPY" : "symbol" }}</td>
      </tr>
    </tbody>
  </table>

  <div class="payment-form">
    <span class="main">Payment information</span><br />
    <div class="inline-container">
      <table>
        <tbody>
          <tr>
            <td colspan="2">
              <mat-form-field appearance="fill">
                <mat-label>Card holder name</mat-label>
                <input matInput placeholder="NAME ON THE CREDIT CARD" [formControl]="card_name_fc" (change)="onCCNameChanged($event)" required />
                <mat-error class="left">
                  <span *ngIf="card_name_fc.hasError('required')">Card holder name is <strong>required</strong></span>
                </mat-error>
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <mat-form-field appearance="fill">
                <mat-label>Credit card number</mat-label>
                <credit-card-number required (valueFilled)="onCCNumberValueFilled($event)" [formControl]="card_number_fc"></credit-card-number>
                <mat-error class="left" *ngIf="card_number_fc.hasError('required')">Card number is <strong>required</strong></mat-error>
                <div class="control_error" *ngIf="card_number_invalid">The card information is <strong>invalid</strong></div>
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>
              <mat-form-field appearance="fill">
                <mat-label>Expire(mm/yy)</mat-label>
                <credit-card-mmyy
                  required
                  (valueFilled)="onCCMmYyValueFilled($event)"
                  [formControl]="card_mmyy_fc"
                  (focus)="onCCMmYyFocus()"
                ></credit-card-mmyy>
                <mat-error class="left" *ngIf="card_mmyy_fc.hasError('required')">Expire date is <strong>required</strong></mat-error>
              </mat-form-field>
              <!-- <div class="control_error" *ngIf="card_mmyy_invalid">Expire date is <strong>invalid</strong></div> -->
            </td>
            <td class="cvv">
              <mat-form-field appearance="fill">
                <mat-label>CVV</mat-label>
                <input matInput placeholder="123" [formControl]="card_cvv_fc" (change)="onCCCvvChanged($event)" inputmode="numeric" enterkeyhint="done" required />
                <mat-error class="left">
                  <span *ngIf="card_cvv_fc.hasError('required')">CVV is <strong>required</strong></span>
                </mat-error>
              </mat-form-field>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <button class="do-topup" mat-flat-button color="primary" (click)="onClickTopUp()">Add data & Extension</button>
</div>
