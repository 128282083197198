<div class="app-start-using-confirmation">
  <div class="description">
    <div class="title"><l sid="21"/>:</div>
    <br/>
    <br/>
    <p><l sid="22"/></p>
    <p>
      <l sid="23"/><strong><l sid="24"/></strong><l sid="25"/>
    </p>
    <p><l sid="28"/></p>
    <div>
      <a mat-stroked-button color="basic" href="https://sakuramobile.jp/esim-instruction_ios" target="_blank">iOS</a>&nbsp;
      <a mat-stroked-button color="basic" href="https://sakuramobile.jp/esim-instruction_android" target="_blank">Android</a>
    </div>
    <br />
    <p><l sid="29"/></p>
    <mat-checkbox [(ngModel)]="c1"><l sid="30"/></mat-checkbox><br/>
    <mat-checkbox [(ngModel)]="c2"><l sid="31"/></mat-checkbox><br/>
    <mat-checkbox [(ngModel)]="c3"><l sid="32"/></mat-checkbox><br/>
    <mat-checkbox [(ngModel)]="c4"><l sid="33"/></mat-checkbox><br/>
  </div>
  <mat-dialog-actions>
    <button mat-raised-button class="row2" color="warn" (click)="onConfirmed()" [disabled]="!c1 || !c2 || !c3 || !c4"><l sid="34"/></button><br />
    <button mat-stroked-button color="Basic" (click)="onCancel()"><l sid="35"/></button>
  </mat-dialog-actions>
</div>
