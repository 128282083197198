export const APP_DICTIONARY_JA = [
  { "id":     1, "txt": "日本語<span style=\"color: red;\">です！</span>" },
  { "id":     2, "txt": "英語" },
  { "id":    10, "txt": "データ通信専用eSIM" },
  { "id":    11, "txt": "契約情報" },
  { "id":    12, "txt": "契約コード" },
  { "id":    13, "txt": "契約作成日" },
  { "id":    14, "txt": "eSIM" },
  { "id":    15, "txt": "プラン" },
  { "id":    16, "txt": "枚数" },
  { "id":    17, "txt": "回線番号" },
  { "id":    18, "txt": "ICCID" },
  { "id":    19, "txt": "通信可能量<br/>/期間" },
  { "id":    20, "txt": "QRコードの取得" },
  { "id":    21, "txt": "注意事項" },
  { "id":    22, "txt": "\"注意事項を確認したのでeSIMを発行します\"ボタンをクリックすると、eSIMが発行されてeSIMプロファイルを取得できます。" },
  { "id":    23, "txt": "eSIMを発行した後は、提供されたQRコード（またはアクティベーションコードを使った手動インストール）を使用して、<strong>48時間以内にスマートフォンにインストール</strong>してください。" },
  { "id":    24, "txt": "コピーしました" },
  { "id":    25, "txt": "インストール<br/>日時" },
  { "id":    26, "txt": "終了<wbr/>日時" },
  { "id":    27, "txt": "###NOT USE###" },
  { "id":    28, "txt": "eSIMプロファイルをデバイスにインストールする方法については、以下のリンクに表示されているPDFを確認してください。" },
  { "id":    29, "txt": "eSIMの発行を今すぐ進めたい場合は、以下のボックスにチェックを入れて、\"注意事項を確認したのでeSIMを発行します\"ボタンを押してください。" },
  { "id":    30, "txt": "上記の指示を読み、48時間以内にeSIMプロファイルをインストールします。" },
  { "id":    31, "txt": "契約したeSIMプランはeSIMがデバイスに追加された日から開始されることを理解しました。" },
  { "id":    32, "txt": "eSIMプロファイルは一度しかインストールできないことを理解しています" },
  { "id":    33, "txt": "eSIMを発行した後のキャンセルと返金ができないことを理解しました。" },
  { "id":    34, "txt": "注意事項を確認したのでeSIMを発行します" },
  { "id":    35, "txt": "閉じる" },
  { "id":    36, "txt": "回線番号" },
  { "id":    37, "txt": "閉じる" },
  { "id":    38, "txt": "eSIM<wbr/>割当日時" },
  { "id":    39, "txt": "###NOT USE###" },
  { "id":    40, "txt": "アクティベーション用QRコードの表示" },
  { "id":    41, "txt": "iOS用マニュアル" },
  { "id":    42, "txt": "Android用マニュアル" },
  { "id":    43, "txt": "" },
  { "id":    44, "txt": "" },
  { "id":    45, "txt": "" },
  { "id":    46, "txt": "" },
  { "id":    47, "txt": "" },
  { "id":    48, "txt": "" },
  { "id":    49, "txt": "" },
  { "id":    50, "txt": "" },
  { "id":    51, "txt": "" },
  { "id":    52, "txt": "" },
  { "id":    53, "txt": "" },
  { "id":    54, "txt": "" },
  { "id":    55, "txt": "" },
  { "id":    56, "txt": "" },
  { "id":    57, "txt": "" },
  { "id":    58, "txt": "" },
  { "id":    59, "txt": "" },
  { "id":    60, "txt": "" },
  { "id":    61, "txt": "" },
  { "id":    62, "txt": "" },
  { "id":    63, "txt": "" },
  { "id":    64, "txt": "" },
  { "id":    65, "txt": "" },
  { "id":    66, "txt": "" },
  { "id":    67, "txt": "" },
  { "id":    68, "txt": "" },
  { "id":    69, "txt": "" },
  { "id":    70, "txt": "" }
];