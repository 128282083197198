import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {

  private _display_subscription: Subscription
  public display: string;

  constructor(
    private _spinnerService: SpinnerService
  ) {
    this.display = 'none';
    this._display_subscription = this._spinnerService.display$.subscribe(
      display => {
        this.display = display;
      }
    )
  }
}
