import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerApiService } from 'src/app/services/customer-api.service';


@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent {

  constructor(
    private _customerApiService: CustomerApiService,
    public router: Router
  ) {
  }

  ngOnInit(): void {
  }

  onSignOutClick() {
  }
}
