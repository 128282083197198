<div class="esim-usage" *ngIf="booking && cf_item && esim_item">
  <h2>Usage check : {{ esim_item.telno }}</h2>
  Booking : <br />
  <table>
    <tbody>
      <tr>
        <td class="title">Booking</td>
        <td><a class="link myesim" [routerLink]="['/booking']" [queryParams]="{ hash: booking.hash }">{{ booking.code }}</a></td>
      </tr>
      <tr>
        <td class="title">Created</td>
        <td>{{ booking.order_created_at | date : "y-MM-dd HH:mm" }}</td>
      </tr>
    </tbody>
  </table>
  <br />
  eSIM : {{ cf_item.category_title }}<br />
  <table class="esim_item">
    <tbody>
      <tr>
        <td class="title">Line number</td>
        <td>{{ esim_item.telno }}</td>
      </tr>
      <tr>
        <td class="title">ICCID</td>
        <td>{{ esim_item.iccid }}</td>
      </tr>
      <tr *ngIf="esim_item.installed_at">
        <td class="title">Volume/Period</td>
        <td>{{ esim_item.volume | decimalTo : 0 : "M" : "G" }} GB / {{ esim_item.period }} days</td>
      </tr>
      <tr>
        <td class="title">Installed</td>
        <td>{{ esim_item.installed_at | date : "y-MM-dd HH:mm" }}</td>
      </tr>
      <tr>
        <td class="title">End</td>
        <td>{{ esim_item.end_at | date : "y-MM-dd HH:mm" }}</td>
      </tr>
      <tr>
        <td class="title">Remaining data</td>
        <td colspan="3">
          <span class="main">{{ quota | bytesTo : 3 : "B" : "GB" }} GB</span>
          <a class="link myesim" [routerLink]="['/topup']" [queryParams]="{ hash: esim_item.hash }">Add data & Extension</a>
        </td>
      </tr>
    </tbody>
  </table>
  <br />
  Usage record :<br />
  <span class="sub-info">At 9:00 every morning, the data usage until mid-night (24:00) yesterday will be reflected.</span>
  <table class="usage-record">
    <thead>
      <tr>
        <th class="date">Date</th>
        <th class="amount">Amount</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let record of usage">
        <td class="date {{record.day_date | weekday : 's' : 'l'}}">{{record.day_date | date : 'MMM-d'}} ({{record.day_date | weekday : 's' : 'u'}})</td>
        <td class="amount {{record.day_date | weekday : 's' : 'l'}}">{{record.byte | bytesTo : 3 : "B" : "GB" }} GB</td>
      </tr>
    </tbody>
  </table>
</div>
