<div class="app-booking" *ngIf="booking">
  <table class="head">
    <tbody>
      <tr>
        <td class="title"><l sid="10" /> : <wbr />{{ booking.code }}</td>
        <!-- <td class="contact_us"><a mat-button color="basic" href="{{contactUsUrl}}" target="_blank">CONTACT US</a></td> -->
      </tr>
    </tbody>
  </table>
  <app-maintenance-info></app-maintenance-info>
  <l sid="11" /> : <br />
  <table>
    <tbody>
      <tr>
        <td class="title"><l sid="12" /></td>
        <td>{{ booking.code }}</td>
      </tr>
      <tr>
        <td class="title"><l sid="13" /></td>
        <td>{{ booking.order_created_at | date : "y-MM-dd HH:mm" }}</td>
      </tr>
    </tbody>
  </table>

  <l sid="14" /> :
  <table *ngFor="let item of booking.order.items">
    <tr>
      <td class="title"><l sid="15" /></td>
      <td>{{ item.package_title }}</td>
    </tr>
    <tr>
      <td class="title"><l sid="16" /></td>
      <td>{{ item.qty }}</td>
    </tr>
    <tr>
      <td colspan="2">
        <table *ngFor="let esim of item.esims" class="esim_item">
          <tbody>
            <tr>
              <td class="title"><l sid="17" /></td>
              <td colspan="2">{{ esim.telno }}&nbsp;({{ esim.status }})</td>
            </tr>
            <tr>
              <td class="title"><l sid="18" /></td>
              <td colspan="2">{{ esim.iccid }}</td>
            </tr>
            <tr *ngIf="esim.installed_at">
              <td class="title"><l sid="19" /></td>
              <td *ngIf="esim.period === null" colspan="2">{{ esim.volume | decimalTo : 0 : "M" : "G" }} GB / {{ item.period }} days</td>
              <td *ngIf="esim.period !== null" colspan="2">{{ esim.volume | decimalTo : 0 : "M" : "G" }} GB / {{ esim.period }} days</td>
            </tr>
            <tr *ngIf="!esim.installed_at">
              <td class="title"><l sid="38" /></td>
              <td colspan="2">{{ esim.start_using_at | date : "y-MM-dd HH:mm" }}</td>
            </tr>
            <tr *ngIf="esim.installed_at">
              <td class="title"><l sid="25" /></td>
              <td colspan="2">{{ esim.installed_at | date : "y-MM-dd HH:mm" }}</td>
            </tr>
            <tr *ngIf="esim.installed_at">
              <td class="title"><l sid="26" /></td>
              <td colspan="2">{{ esim.end_at | date : "y-MM-dd HH:mm" }}</td>
            </tr>
            <tr *ngIf="esim.installed_at && isToolAvailable(esim.status) && false">
              <td class="title">Tools</td>
              <td class="value" colspan="2">
                <a *ngIf="!in_maintenance.in_schedule" class="link myesim" [routerLink]="['/topup']" [queryParams]="{ hash: esim.hash }"
                  >Add data & Extension</a
                >
                <a *ngIf="in_maintenance.in_schedule" class="link myesim disabled">Add data & Extension</a>
                <a *ngIf="!in_maintenance.in_schedule" class="link myesim" [routerLink]="['/usage']" [queryParams]="{ hash: esim.hash }">Usage</a>
                <a *ngIf="in_maintenance.in_schedule" class="link myesim disabled">Usage</a>
              </td>
            </tr>
            <tr *ngIf="!esim.installed_at && isToolAvailable(esim.status)">
              <td class="title">SM-DP address (iOS)</td>
              <td class="value" [innerHTML]="smDpAddressHtml(esim.activation_code)"></td>
              <td class="copy">
                <button mat-icon-button (click)="onCopySmDpAddress(smDpAddress(esim.activation_code))"><mat-icon> content_copy </mat-icon></button>
              </td>
            </tr>
            <tr *ngIf="!esim.installed_at && isToolAvailable(esim.status)">
              <td class="title">Activation code (iOS)</td>
              <td class="value" [innerHTML]="activationCodeHtml(esim.activation_code)"></td>
              <td class="copy">
                <button mat-icon-button (click)="onCopyActivationCode(activationCode(esim.activation_code))"><mat-icon> content_copy </mat-icon></button>
              </td>
            </tr>
            <tr *ngIf="!esim.installed_at && isToolAvailable(esim.status)">
              <td class="title">Activation code (Android)</td>
              <td class="value" [innerHTML]="fullActivationCodeHtml(esim.activation_code)"></td>
              <td class="copy">
                <button mat-icon-button (click)="onCopyFullActivationCode(esim.activation_code)"><mat-icon> content_copy </mat-icon></button>
              </td>
            </tr>
            <tr *ngIf="!esim.installed_at && isToolAvailable(esim.status)">
              <td colspan="3">
                <button id="activation-qr-code-button" mat-flat-button color="primary" (click)="onShowQRCode(esim.telno, esim.activation_code)">
                  <l sid="40" />
                </button>
                &nbsp;
                <a mat-stroked-button color="basic" href="https://sakuramobile.jp/esim-instruction_ios" target="_blank"><l sid="41" /></a>&nbsp;
                <a mat-stroked-button color="basic" href="https://sakuramobile.jp/esim-instruction_android" target="_blank"><l sid="42" /></a><br />
                <span class="sub-info"> </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="buttons-container">
          <div *ngFor="let blank_esim of item.blank_esims" class="button-container">
            <button mat-flat-button color="primary" (click)="onStartUsingESim(item.hash)">{{ blank_esim }} : <l sid="20" /></button>
          </div>
        </div>
      </td>
    </tr>
  </table>
</div>
