import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nextDayToDate'
})
export class NextDayToDatePipe implements PipeTransform {
  transform(value: string, type: 'short' | 'long', offset: number ): Date {

    const search_value = value.toLowerCase();
    const short_days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    const long_days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

    const day_tmp = new Date();
    day_tmp.setDate(day_tmp.getDate() + offset);

    let targetDay_index = 0;
    if (type === 'long') {
      targetDay_index = long_days.indexOf(search_value);
    }
    else {
      targetDay_index = short_days.indexOf(search_value);
    }

    let startDay_index = day_tmp.getDay();

    let next_diff_index = targetDay_index - startDay_index;
    if (startDay_index > targetDay_index) {
      next_diff_index = targetDay_index + 7 - startDay_index;
    }
    day_tmp.setDate(day_tmp.getDate() + next_diff_index);
    return day_tmp;
  }
}
