// Check detail of this : 
// https://v15.material.angular.io/components/form-field/examples#form-field-custom-control

import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, AfterViewInit, Output, EventEmitter, ElementRef, Input, Optional, OnDestroy, ViewChild, Inject, Self } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  NgControl,
  Validators,
} from '@angular/forms';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { toNumberString, fixCustomNgInputField } from 'src/app/common';

/** Data structure for holding telephone number. */
export class CreditCardMmYy {
  constructor(public mm: string, public yy: string) { }
}

@Component({
  selector: 'credit-card-mmyy',
  templateUrl: './credit-card-mmyy.component.html',
  styleUrls: ['./credit-card-mmyy.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: CreditCardMmyyComponent }],
  host: {
    '[class.credit-card-mmyy-floating]': 'shouldLabelFloat',
    '[id]': 'id',
  },
})

export class CreditCardMmyyComponent implements AfterViewInit, ControlValueAccessor, MatFormFieldControl<CreditCardMmYy>, OnDestroy {
  static nextId = 0;
  @ViewChild('mm') mmInput: ElementRef<HTMLInputElement>;
  @ViewChild('yy') yyInput: ElementRef<HTMLInputElement>;

  parts = this._formBuilder.group({
    mm: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
    yy: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
  });
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = 'credit-card-input';
  id = `credit-card-mmyy-input-${CreditCardMmyyComponent.nextId++}`;
  onChange = (_: any) => { };
  onTouched = () => { };

  get empty() {
    const {
      value: { mm, yy },
    } = this.parts;

    return !mm && !yy;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input('aria-describedby') userAriaDescribedBy: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): CreditCardMmYy | null {
    if (this.parts.valid) {
      const {
        value: { mm, yy },
      } = this.parts;
      // console.log(`CreditCardMmyyComponent::@Input::get-value : mm=${mm}, yy=${yy}`);
      return new CreditCardMmYy(mm!, yy!);
    }
    return null;
  }
  set value(cr_number: CreditCardMmYy | null) {
    const { mm, yy } = cr_number || new CreditCardMmYy('', '');
    this.parts.setValue({ mm, yy });
    // console.log(`CreditCardMmyyComponent::@Input::set-value : mm=${mm}, yy=${yy}`);
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return this.parts.invalid && this.touched;
  }

  @Output() valueFilled = new EventEmitter<string>();
  fireValueFilled() {    
    this.valueFilled.emit(`${this.value?.mm}${this.value?.yy}`)
  }

  constructor(
    private _formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  private _ui_factors = [
    {hints: ['iphone', 'crios'], judge: 'iPhone/Chrome', factor: 0.95},
    {hints: ['iphone', 'edgios'], judge: 'iPhone/Edge', factor: 0.95},
    {hints: ['iphone', 'fxios'], judge: 'iPhone/Firefox', factor: 0.96},
    {hints: ['iphone', 'safari'], judge: 'iPhone/Safari', factor: 1.0},
    {hints: ['iphone'], judge: 'iPhone/Other', factor: 0.96}, // Opera, Sleipnir
    {hints: ['android', 'firefox'], judge: 'Android/Firefox', factor: 0.9},
    {hints: ['android', 'chrome', 'mobile', 'safari', 'edga'], judge: 'Android/edge', factor: 0.85},
    {hints: ['android', 'chrome', 'mobile', 'safari'], judge: 'Android/Chrome,Other', factor: 0.85},
    {hints: ['chrome'], judge: 'Desktop/Chrome,Other', factor: 0.96},
    {hints: ['firefox'], judge: 'Desktop/Firefox', factor: 0.96},
    {hints: ['safari'], judge: 'Desktop/Safari', factor: 0.9}
  ];

  ngAfterViewInit(): void {
    const container_tags = document.getElementsByTagName('credit-card-mmyy');
    if (container_tags.length <= 0 || !container_tags[0]) {
      return;
    }
    fixCustomNgInputField(<HTMLElement>container_tags[0], 'credit-card-mmyy-input-container', 'card-mmyy-field-element', this._ui_factors);
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
      this.fireValueFilled();
    }
  }

  autoFocusNext(control: AbstractControl, nextElement?: HTMLInputElement): void {
    if (!control.errors && nextElement) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (control.value.length < 1) {
      this._focusMonitor.focusVia(prevElement, 'program');
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.credit-card-mmyy-input-container',
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
    if (this.parts.controls.yy.valid) {
      this._focusMonitor.focusVia(this.yyInput, 'program');
    } else {
      this._focusMonitor.focusVia(this.mmInput, 'program');
    }
  }

  writeValue(cr_mmyy: CreditCardMmYy | null): void {
    this.value = cr_mmyy;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
    control.setValue(this.toNumberString(control.value));
    this.autoFocusNext(control, nextElement);
    this.onChange(this.value);
  }

  toNumberString(input: string): string {
    return toNumberString(input);
  }
}
