import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { ResponseNotification } from 'src/app/components/share/simple-notification/simple-notification.component';

@Component({
  selector: 'app-date-pick',
  templateUrl: './date-pick.component.html',
  styleUrls: ['./date-pick.component.scss']
})
export class DatePickComponent {
  public title: string;
  public message: string;
  public on_confirm: Function;
  public label_confirm: string;
  public label_cancel: string;

  public selected: Date | null;

  constructor(
    private dialogRef: MatDialogRef<DatePickComponent>,
    private _snackBar: MatSnackBar,
    private _sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.title = data.title;
    this.message = data.message;
    this.label_confirm = data.label_confirm;
    this.label_cancel = data.label_cancel;
    this.on_confirm = data.on_confirm;
    this.selected = data.selected;
  }

  public get safe_message(): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(this.message);
  }

  onConfirmed() {
    console.log(`ConfirmationComponent::onConfirmed`);
    this.on_confirm(this.selected).then((fn_result) => {
      ResponseNotification(this._snackBar, fn_result);
      this.dialogRef.close(this.label_confirm);
    });
  }

  onCancel() {
    console.log("ConfirmationComponent::onCancel");
    this.dialogRef.close(this.label_cancel);
  }

}
