import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimalTo'
})
export class DecimalToPipe implements PipeTransform {

  transform(value: number,
    dicimal_digits: number,
    from_unit: '0' | 'K' | 'M' | 'G' | string,
    to_unit: '0' | 'K' | 'M' | 'G' | string): string {

    const K_UNIT = 1000;
    let current_value = value;
    const up_from_unit = from_unit.toUpperCase();
    const up_to_unit = to_unit.toUpperCase();
    if (up_from_unit === '0') {
      switch (to_unit.toUpperCase()) {
        case '0':
          break;
        case 'K':
          current_value = (current_value / K_UNIT);
          break;
        case 'M':
          current_value = (current_value / (K_UNIT * K_UNIT));
          break;
        case 'G':
          current_value = (current_value / (K_UNIT * K_UNIT * K_UNIT));
          break;
        default:
          break;
      }
    }
    else if (up_from_unit === 'K') {
      switch (to_unit.toUpperCase()) {
        case '0':
          current_value = (current_value * K_UNIT);
          break;
        case 'K':
          break;
        case 'M':
          current_value = (current_value / (K_UNIT));
          break;
        case 'G':
          current_value = (current_value / (K_UNIT * K_UNIT));
          break;
        default:
          break;
      }
    }
    else if (up_from_unit === 'M') {
      switch (to_unit.toUpperCase()) {
        case '0':
          current_value = (current_value * K_UNIT * K_UNIT);
          break;
        case 'K':
          current_value = (current_value * K_UNIT);
          break;
        case 'M':
          break;
        case 'G':
          current_value = (current_value / (K_UNIT));
          break;
        default:
          break;
      }
    }
    else if (up_from_unit === 'G') {
      switch (to_unit.toUpperCase()) {
        case '0':
          current_value = (current_value * K_UNIT * K_UNIT * K_UNIT);
          break;
        case 'K':
          current_value = (current_value * K_UNIT * K_UNIT);
          break;
        case 'M':
          current_value = (current_value * K_UNIT);
          break;
        case 'G':
          break;
        default:
          break;
      }
    }
    return current_value.toFixed(dicimal_digits);
  }
}
