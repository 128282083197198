import { APP_DICTIONARY_JA } from "src/app/dictionaries/ja_dic";
import { APP_DICTIONARY_EN } from "src/app/dictionaries/en_dic";

const STORAGE_KEY = 'mobilenet::oem_my_esim';
const FALLBACK_LANG = "en";
const AVAILABLE_LANGS = [FALLBACK_LANG, "ja"];

let _language: string = FALLBACK_LANG;
let _dictionary: any;

export function current_language(): string {
  return _language;
}

export function localizeString(id: number): string {
  if (_dictionary) {
    const found = _dictionary.find(item => {return item.id === id});
    if (found) {
      return found.txt;
    }
    return `TRANSRATION NOT FOUND ${_language}:${id}`;
  }
  return `THE DICTIONARY IS NOT INITIALIZED ${_language}`;
}

function toLanguageString(language: string): string {
  if (!language || language.length <= 0) {
    _language = FALLBACK_LANG;
  }
  
  const splitted = language.split('-');
  let candidate = language;
  if (splitted.length > 1) {
    candidate = splitted[0];
  }
  if (!AVAILABLE_LANGS.find(c => {return c == candidate})) {
    return FALLBACK_LANG;
  }
  return candidate;
}

export function app_localization_initialize(): void {
  const storage_data_string = localStorage.getItem(STORAGE_KEY);
  if (storage_data_string) {
    const storage_data = JSON.parse(storage_data_string);
    if (storage_data.language) {
      _language = storage_data.language;   
    }
  }
  else {
    _language = toLanguageString(window.navigator.language);
    localStorage.setItem(STORAGE_KEY, JSON.stringify({
      "language": _language
    }));
  }
  if (_language == 'ja') {
    _dictionary = APP_DICTIONARY_JA;
  }
  else {
    _dictionary = APP_DICTIONARY_EN;
  }
}

export function L(id) {
  return localizeString(id);
}

export function LD() {
  return _dictionary;
}
