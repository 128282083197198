import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { SpinnerService } from 'src/app/services/spinner.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerApiService {
  public last_message: any;

  constructor(
    private _spinnerService: SpinnerService,
    private httpClient: HttpClient,
  ) {
    this.last_message = null;
  }

  private resolveWithMessage(resolver: any, value: any): void {
    this.last_message = {
      result: value.result,
      message: value.message
    }
    resolver(value);
  }

  public async getBooking(access_hash: string): Promise<any> {
    return new Promise((resolve) => {
      this._spinnerService.start();
      this.httpClient.get(`${environment.api_root}/api/v1/cf_bookings/my/${access_hash}`, {}).subscribe({
        next: (response) => {
          this._spinnerService.stop();
          // console.log("CustomerApiService::getBooking : next : data=",response);
          this.resolveWithMessage(resolve, response);
          return;
        },
        error: (error) => {
          this._spinnerService.stop();
          // console.log("CustomerApiService::getBooking : error : data=",error);
          this.resolveWithMessage(resolve, error.error);
          return;
        }
      });
    });
  }

  public async postStartUsingEsim(access_hash: string): Promise<any> {
    return new Promise((resolve) => {
      this._spinnerService.start();
      this.httpClient.post(`${environment.api_root}/api/v1/cf_items/start_using_sim/my/${access_hash}`, {}, {}).subscribe({
        next: (response) => {
          this._spinnerService.stop();
          // case success
          this.resolveWithMessage(resolve, response);
          return;
        },
        error: (error) => {
          this._spinnerService.stop();
          // case error
          this.resolveWithMessage(resolve, error);
          return;
        }
      });
    });
  }

  public async getEsimUsage(access_hash: string): Promise<any> {
    return new Promise((resolve) => {
      this._spinnerService.start();
      this.httpClient.get(`${environment.api_root}/api/v1/esims/usage/my/${access_hash}`, {}).subscribe({
        next: (response) => {
          this._spinnerService.stop();
          // console.log("CustomerApiService::getBooking : next : data=",response);
          this.resolveWithMessage(resolve, response);
          return;
        },
        error: (error) => {
          this._spinnerService.stop();
          // console.log("CustomerApiService::getBooking : error : data=",error);
          this.resolveWithMessage(resolve, error.error);
          return;
        }
      });
    });
  }

  public async getEsimTopup(access_hash: string): Promise<any> {
    return new Promise((resolve) => {
      this._spinnerService.start();
      this.httpClient.get(`${environment.api_root}/api/v1/esims/topup/my/${access_hash}`, {}).subscribe({
        next: (response) => {
          this._spinnerService.stop();
          // console.log("CustomerApiService::getBooking : next : data=",response);
          this.resolveWithMessage(resolve, response);
          return;
        },
        error: (error) => {
          this._spinnerService.stop();
          // console.log("CustomerApiService::getBooking : error : data=",error);
          this.resolveWithMessage(resolve, error.error);
          return;
        }
      });
    });
  }

  public async postEsimTopup(access_hash: string, period: number, volume: number, total: number, vat: number, total_vat: number, token: string): Promise<any> {
    return new Promise((resolve) => {
      this._spinnerService.start();
      this.httpClient.post(`${environment.api_root}/api/v1/esims/topup/my/${access_hash}`, {
        period: period,
        volume: volume,
        total: total,
        vat: vat,
        total_vat: total_vat,
        token: token
      }, {}).subscribe({
        next: (response) => {
          this._spinnerService.stop();
          // case success
          this.resolveWithMessage(resolve, response);
          return;
        },
        error: (error) => {
          this._spinnerService.stop();
          // case error
          this.resolveWithMessage(resolve, error);
          return;
        }
      });
    });
  }

  public async getInMaintenance(): Promise<any> {
    return new Promise((resolve) => {
      this._spinnerService.start();
      this.httpClient.get(`${environment.api_root}/api/v1/maintenance_schedules/in_maintenance`, {}).subscribe({
        next: (response) => {
          this._spinnerService.stop();
          // console.log("CustomerApiService::getInMaintenance : next : data=",response);
          this.resolveWithMessage(resolve, response);
          return;
        },
        error: (error) => {
          this._spinnerService.stop();
          // console.log("CustomerApiService::getInMaintenance : error : data=",error);
          this.resolveWithMessage(resolve, error.error);
          return;
        }
      });
    });
  }

  public async getPlannedMaintenances(): Promise<any> {
    return new Promise((resolve) => {
      this._spinnerService.start();
      this.httpClient.get(`${environment.api_root}/api/v1/maintenance_schedules/planned_maintenances`, {}).subscribe({
        next: (response) => {
          this._spinnerService.stop();
          // console.log("OperatorApiService::getInMaintenance : next : data=",response);
          this.resolveWithMessage(resolve, response);
          return;
        },
        error: (error) => {
          this._spinnerService.stop();
          // console.log("OperatorApiService::getInMaintenance : error : data=",error);
          this.resolveWithMessage(resolve, error.error);
          return;
        }
      });
    });
  }
}
