<div role="group" class="credit-card-mmyy-input-container"
     [formGroup]="parts"
     [attr.aria-labelledby]="_formField.getLabelId()"
     (focusin)="onFocusIn($event)"
     (focusout)="onFocusOut($event)">
  <input class="card-mmyy-field-element"
         formControlName="mm" 
         maxLength="2"
         size="6"
         aria-label="1st field"
         inputmode="numeric"
         enterkeyhint="done"
         (input)="_handleInput(parts.controls.mm, yy)"
         #mm>
  <span class="card-mmyy-field-spacer">&frasl;</span>
  <input class="card-mmyy-field-element"
         formControlName="yy"
         maxLength="2"
         size="6"
         aria-label="2nd field"
         inputmode="numeric"
         enterkeyhint="done"
         (input)="_handleInput(parts.controls.yy)"
         (keyup.backspace)="autoFocusPrev(parts.controls.yy, mm)"
         #yy>
</div>
