<div class="app-date-pick">
  <table class="content">
    <tbody>
      <tr>
        <td class="message">
          <div class="title">{{title}}</div>
          <br/>
          <div class="message" [innerHTML]="safe_message"></div>
        </td>
        <td rowspan="2" class="picker">
          <mat-calendar [(selected)]="selected"></mat-calendar>
        </td>
      </tr>
      <tr>
        <td class="selected-date">
          Selecting date : <br/><span class="selecting-date">{{selected | date : "y-MM-dd"}}</span><br/>
          <mat-dialog-actions>
            <button mat-raised-button color="warn" (click)="onConfirmed()">{{this.label_confirm}}</button>&nbsp;
            <button mat-stroked-button color="Basic" (click)="onCancel()">{{this.label_cancel}}</button>
          </mat-dialog-actions>
      </tr>
    </tbody>
  </table>
</div>
