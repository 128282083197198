export const NotificationType = {
  info: 1,
  warn: 2,
  error: 3,
  fatal: 4,
} as const;

export const WDayKey = [
  "SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT",
] as const;

export function wday(date: Date): string {
  return WDayKey[date.getDay()];
}

export function toNumberString(input: string): string {
  const numbersArray = input.match(/[0-9]/g);
  if (numbersArray === null) {
    return "";
  }
  return numbersArray.join("");
}

function uaIs(texts): boolean {
  let result = true;
  texts.forEach(text => {
    if (!navigator.userAgent.toLowerCase().includes(text.toLowerCase())) {
      result = false;
      return;
    }
  });
  // console.log(`${result} : ${navigator.userAgent} : ${JSON.stringify(texts)}`);
  return result;
}


function findNgUiFactor(factor_list: Array<any>): any {
  let result: any | null = null;
  factor_list.forEach(candidate => {
    if (!result) {
      if (uaIs(candidate.hints)) {
        result = candidate;
      }
    }
  })
  if (!result) {
    result = {hints: [], judge: 'Unknown', factor: 1.0};
  }
  return result;
}

export function fixCustomNgInputField(component_el: HTMLElement, input_container_class_name: string, input_fields_class_name, factor_list: Array<any>): any {
    const factor = findNgUiFactor(factor_list);

    if (!component_el.parentElement || !component_el.parentElement.parentElement) {
      return;
    }

    const disp_container = component_el.parentElement.parentElement;
    const pr = disp_container.getClientRects()[0];

    const input_containers = component_el.getElementsByClassName(input_container_class_name);
    if (input_containers.length <= 0 ||
      !input_containers[0]) {
      return;
    }
    const input_container = input_containers[0];

    let fmin = 65535;
    let fmax = 0;
    const input_fields = input_container.getElementsByClassName(input_fields_class_name);
    Array.from(input_fields).forEach((el) => {
      const fr = el.getClientRects()[0];
      if (fr.left < fmin) {
        fmin = fr.left;
      }
      if (fr.right > fmax) {
        fmax = fr.right;
      }
    });

    const fwidth = fmax - fmin;
    const res_str = `P{x=${pr.x} : w=${pr.width}} F{x=${fmin} : w=${fwidth}}`;

    const pad = fmin - pr.x;
    const expect_width = pr.width - (pad * 2);
    const ratio = expect_width / fwidth;
    Array.from(input_fields).forEach((el) => {
      const fr = el.getClientRects()[0];
      const iel = <HTMLInputElement>el;
      const aply_str = `${fr.width * ratio * factor.factor}px`
      iel.style.width = aply_str;
      // console.log(iel, fr.width, ratio, iel.style.width, aply_str);
    });
}