import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'weekday'
})
export class WeekdayPipe implements PipeTransform {

  transform(value: string, length: 's' | 'l' | string = 's', case_to: 'l' | 'u' | 'n' | string = 'n'): string {
    const short_days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const long_days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dt = new Date(value);
    let found = "";
    if (length == 's') {
      found = short_days[dt.getDay()];
    }
    else if (length == 'l') {
      found = long_days[dt.getDay()];
    }
    if (case_to === 'l') {
      return found.toLowerCase();
    }
    else if (case_to === 'u') {
      return found.toUpperCase();
    }
    return found;
  }

}
