import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { ResponseNotification } from 'src/app/components/share/simple-notification/simple-notification.component';
import { CustomerApiService } from 'src/app/services/customer-api.service';

import { StartUsingConfirmationComponent } from 'src/app/components/dialogs/start-using-confirmation/start-using-confirmation.component';
import { QrCodeComponent } from 'src/app/components/dialogs/qr-code/qr-code.component';

@Component({
  selector: 'app-installation',
  templateUrl: './installation.component.html',
  styleUrls: ['./installation.component.scss']
})
export class InstallationComponent {
  private _booking_hash: string;
  public booking: any;
  public items_for_installation: Array<any>;

  constructor(
    private _CustomerApiService: CustomerApiService,
    public router: Router,
    private _snackBar: MatSnackBar,
    private _route: ActivatedRoute,
    public dialog: MatDialog
  ) {
    this._booking_hash = "";
    this.items_for_installation = [];
  }

  ngOnInit(): void {
    this._route.queryParamMap.subscribe((params) => {
      console.log("InstallationComponent::ngOnInit : params ", params);
      const access_hash = params.get('hash');
      if (access_hash) {
        this._booking_hash = access_hash;
        console.log(`InstallationComponent::ngOnInit : booking hash = ${this._booking_hash}`)
        this.updatePage();
      }
    });
  }

  private updatePage() {
    console.log(`InstallationComponent::updatePage - hash : hash=${this._booking_hash}`);
    this._CustomerApiService.getBooking(this._booking_hash).then((booking_result) => {
      if (booking_result.result) {
        console.log("InstallationComponent::updatePage - booking : ", booking_result);
        this.booking = booking_result.data;
        this.items_for_installation = [];
        this.booking.order.items.forEach((item, item_index) => {
          let esim_items = <any>[];
          item.esims.forEach((esim, esim_index) => {
            esim_items.push({
              esim: esim,
              esim_index: esim_index + 1
            });
          });
          // create blank esims (assign esim from stocks later)
          for (let esim_index = esim_items.length; esim_index < item.qty; esim_index++) {
            console.log(`InstallationComponent::updatePage index=${esim_index} : length=${esim_items.length} : qty=${item.qty}`)
            esim_items.push({
              esim: null,
              esim_index: esim_index + 1
            })
          }
          esim_items.push()
          let installation_item = {
            item: item,
            item_index: item_index + 1,
            esims: esim_items,
          }
          console.log("InstallationComponent::updatePage - installation_item : ", installation_item);
          this.items_for_installation.push(installation_item);
        });
      }
      else {
        ResponseNotification(this._snackBar, booking_result);
      }
    });
  }

  public onShowQRCode(telno: string, full_code: string): void {
    console.log("InstallationComponent::onShowQRCode");

    const dialogRef = this.dialog.open(QrCodeComponent, {
      data: {
        telno: telno,
        full_code: full_code,
        sm_dp_address: this.smDpAddress(full_code),
        activation_code: this.acctivationCode(full_code),
        qr_code_data: `LPA:${full_code}`
      }
    });
    dialogRef.afterClosed().subscribe((closed_by) => {
      console.log(`InstallationComponent::onShowQRCode dialog closed`);
    });
  }

  public onStartUsingESim(cf_item_hash): void {
    console.log("InstallationComponent::onStartUsingESim");
    console.log(this.items_for_installation);

    const dialogRef = this.dialog.open(StartUsingConfirmationComponent, { data: { cf_item_hash: cf_item_hash } });
    dialogRef.afterClosed().subscribe((closed_by) => {
      if (closed_by === 'confirmed') {
        this.updatePage();
      }
    });
  }

  public smDpAddress(full_code: string): string {
    return full_code.split('$')[1];
  }

  public acctivationCode(full_code: string): string {
    return full_code.split('$')[2];
  }
}
