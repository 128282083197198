import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { ResponseNotification } from 'src/app/components/share/simple-notification/simple-notification.component';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  public title: string;
  public message: string;
  public on_confirm: Function;
  public label_confirm: string;
  public label_cancel: string;

  constructor(
    private dialogRef: MatDialogRef<ConfirmationComponent>,
    private _snackBar: MatSnackBar,
    private _sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.title = data.title;
    this.message = data.message;
    this.label_confirm = data.label_confirm;
    this.label_cancel = data.label_cancel;
    this.on_confirm = data.on_confirm;
  }

  public get safe_message(): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(this.message);
  }

  ngOnInit(): void {
  }

  onConfirmed() {
    console.log(`ConfirmationComponent::onConfirmed`);
    this.on_confirm().then((fn_result) => {
      ResponseNotification(this._snackBar, fn_result);
      this.dialogRef.close(this.label_confirm);
    });
  }

  onCancel() {
    console.log("ConfirmationComponent::onCancel");
    this.dialogRef.close(this.label_cancel);
  }
}
